<template>
  <v-app>
    <DevMergeHistoryAlertInfo v-if="isSPA" class="ma-4" />

    <div v-if="isLoading" class="d-flex align-center justify-center h-full">
      <ApLoading text="Loading..." />
    </div>

    <div v-else-if="isError" class="pa-4 ap-red-10">
      <ApLogo />

      <div class="d-flex justify-center pa-8">
        <div class="d-flex align-center">
          <div class="mr-2">
            <v-img src="@/assets/images/cyborg.svg" />
          </div>
          <div>
            <h1 class="text-h6 ap-dark-blue--text font-weight-bold">
              CleanData Server Message
            </h1>
            <div class="ap-dark-gray--text">
              CleanData is hard at work analyzing data to identify and fix data
              quality issues.
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-end">
        <v-btn color="ap-red" dark @click="getDupeSet">Try again</v-btn>
      </div>
    </div>

    <DupeGrid
      v-else-if="response && isMerged"
      type="history"
      title="Record Audit Trail"
      :entity-id="entityId"
      :entity-name="entityName"
      :crm-current-url="crmCurrentUrl"
      :user-email-logged-in="userEmailLoggedIn"
      :response="response"
      style="width: 100%"
    />

    <div v-else class="d-flex align-center justify-center h-full">
      <v-alert
        text
        outlined
        color="ap-orange"
        type="info"
        style="width: 350px"
        class="mx-auto"
      >
        This record has not yet been merged.
      </v-alert>
    </div>
  </v-app>
</template>

<script>
import ApLogo from '@/components/common/ApLogo'
import DupeGrid from '@/components/dupegrid/DupeGrid'
import ApLoading from '@/components/common/ApLoading'
import { getDupeSetByRecordId } from '@/api/dupe-sets/dupe-sets'
import { mapState } from 'vuex'
import { isSPA } from '@/utils/env'
import { dupeSetsStates } from '@/api/dupe-sets/constants'

export default {
  components: {
    DupeGrid,
    ApLoading,
    ApLogo,
    DevMergeHistoryAlertInfo: () =>
      import('@/components/dev/DevMergeHistoryAlertInfo.vue'),
  },
  data() {
    return {
      isMerged: false,
      isSPA,
      entityId: null,
      entityName: null,
      response: null,
      isLoading: false,
      isError: false,
    }
  },
  computed: {
    ...mapState('auth', ['agentId', 'contextMessage']),
    userEmailLoggedIn() {
      return this.contextMessage.email
    },
    crmCurrentUrl() {
      return this.contextMessage.url
    },
  },
  created() {
    const { entity_key: entityKey, entity_name: entityName } = this.$route.query
    this.entityId = entityKey
    this.entityName = entityName
    this.getDupeSet()
  },
  methods: {
    async getDupeSet() {
      this.isError = false
      this.isLoading = true
      try {
        this.response = await getDupeSetByRecordId(this.entityId)
        this.isMerged = this.response.state === dupeSetsStates.FINISHED
      } catch {
        this.isError = true
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
